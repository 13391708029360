body {
  min-height: 100vh !important;
  background-image: url(background-stg.svg);
  background-size: 100% auto;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.main-content {
  padding-top: 80px;
  min-height: 60vh;
}

.form-control[type="text"], 
.form-control[type="password"],
.form-control[type="email"] {
  border-color: #d6d500 !important;
}

.message-box {
  color: #004833;
  padding: 5px 24px;
  border-top: 1px solid #93cb4d;
  border-bottom: 1px solid #93cb4d;
  margin-top: 20px;
  background-color: rgba(229, 237, 213, 0.9);
}

.custom-link {
  text-decoration: underline !important;
  cursor: pointer !important;
}

div.alert.alert-danger ul {
  margin-bottom: 0px !important;
}

div.activate-message.alert {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  margin-bottom: 0.5rem !important;
}
